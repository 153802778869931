import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../App/Main/Classes/App";
import {
  blueGradient,
  darkestBlue,
  lightestBlue,
} from "../App/Main/Styles/constants";
import { Motion } from "../App/Main/Components/Motion";
import { setDefaultConsent, setFullConsent } from "./init";

const cookies = "cookies_accepted";

const pcFirst =
  "Těší nás, že jste s námi. Za účelem zlepšení funkčnosti našeho webu a poskytnutí relevantního obsahu a služeb používáme soubory cookie.";
const pcTablet =
  "Pokud souhlasíte s používáním souborů cookie, klikněte na tlačítko „Přijmout vše“. Pro více informací o tom, jak zpracováváme osobní údaje a používáme soubory cookie si prosím přečtěte naše";
const mobileText =
  "Pokud souhlasíte s používáním souborů cookie, klikněte na tlačítko „Přijmout vše“. Pro více informací si prosím přečtěte naše";
const allEndText = (
  <>
    {" "}
    <a
      href="https://vyhledavaniexekuci.cz/zasady"
      target="_blank"
      rel="noreferrer"
      style={{ color: "white" }}
    >
      Zásady ochrany osobních údajů
    </a>{" "}
    a{" "}
    <a
      href="https://vyhledavaniexekuci.cz/podminky"
      target="_blank"
      rel="noreferrer"
      style={{ color: "white" }}
    >
      Cookies policy
    </a>
  </>
);

function acceptCookies(all = false) {
  if (!localStorage) return;
  localStorage.setItem(cookies, all ? "all" : "true");
}

export function areCookiesAccepted() {
  const cookies = "cookies_accepted";
  if (!localStorage) return true;
  return Boolean(localStorage?.getItem(cookies) === "all");
}

function acceptedThisSession() {
  try {
    if (!sessionStorage) return false;
    return sessionStorage.getItem("cookies_session") === "true";
  } catch (error) {
    return false;
  }
}

function acceptSession() {
  try {
    if (!sessionStorage) return;
    sessionStorage.setItem("cookies_session", "true");
  } catch (e) {
    return;
  }
}

export default function CookieBar({ open, setOpen }) {
  const [shown, setShown] = useState(
    open !== undefined ? open : !areCookiesAccepted()
  );
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  useEffect(() => {
    if (open !== undefined && shown != open) {
      setShown(open);
    }
  }, [open]);

  if (acceptedThisSession()) return <></>;

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: shown ? "220px" : "0px",
        position: "fixed",
        bottom: 0,
        zIndex: 10020,
        //boxShadow: "0px -1px 5px 0px rgba(115,117,122,0.75)",
        transition: "max-height .4s ease-in-out",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          minHeight: "100px",
          background: blueGradient,
          opacity: 0.9,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            minHeight: "100px",
            display: "flex",
            flexDirection: "row",
            px: "0px !important",
          }}
        >
          <Typography
            sx={{ color: "white", mx: "20px", my: "20px", fontSize: "13px" }}
          >
            {!(mobile || tablet) ? <>{pcFirst} </> : ""}
            {mobile ? mobileText : pcTablet}
            {allEndText}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              ml: "auto",
              mr: "20px",
              mt: "20px",
            }}
          >
            <Motion>
              <Box
                sx={{
                  minWidth: "120px",
                  height: "36px",
                  bgcolor: darkestBlue,
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  cursor: "pointer",
                  opacity: 1,
                }}
                onClick={() => {
                  setFullConsent();
                  acceptCookies(true);
                  if (setOpen) setOpen(false);
                  setShown(false);
                }}
              >
                <Typography
                  sx={{ color: "white", fontWeight: "bold", fontSize: "13px" }}
                >
                  Přijmout vše
                </Typography>
              </Box>
            </Motion>
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                mt: "12px",
                cursor: "pointer",
                fontSize: "13px",
              }}
              onClick={() => {
                setDefaultConsent();
                acceptCookies();
                acceptSession();
                if (setOpen) setOpen(false);
                setShown(false);
              }}
            >
              Jen nezbytné
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
