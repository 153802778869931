import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
  setConsent,
} from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, getBytes } from "firebase/storage";
import { ReCaptchaV3Provider, initializeAppCheck } from "firebase/app-check";
import { areCookiesAccepted } from "./CookieBar";

// INIT
const firebaseConfig = {
  apiKey: "AIzaSyCymdMRFszrXlm2BKzrP9Oc0ZKe2rZthS0",
  authDomain: "exekuceweb.firebaseapp.com",
  projectId: "exekuceweb",
  storageBucket: "exekuceweb.appspot.com",
  messagingSenderId: "741944214811",
  appId: "1:741944214811:web:12795dfd7726682f193fbe",
  measurementId: "G-P8125BZW6K",
};

const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6Ld7WSYjAAAAAODJyinEzzetHN0JT5jLGkErEmKd"),
  isTokenAutoRefreshEnabled: true,
});

export const db = getFirestore(app);

// ANALYTICS
//#region Analytics
const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, true);

export function logAnalytics(name, additional_params = {}) {
  if (Object.keys(additional_params).length === 0) {
    logEvent(analytics, name);
  } else {
    logEvent(analytics, name, additional_params);
  }
}

// Full default
const fullConsent = {
  ad_storage: "granted",
  ad_user_data: "granted",
  ad_personalization: "granted",
  analytics_storage: "granted",
  functionality_storage: "granted",
  personalization_storage: "granted",
  security_storage: "granted",
};

// Consent Required Always
const partialConsent = {
  ad_storage: "denied",
  ad_user_data: "denied",
  ad_personalization: "denied",
  analytics_storage: "denied",
  functionality_storage: "granted",
  personalization_storage: "granted",
  security_storage: "granted",
};

export function setDefaultConsent() {
  setConsent(partialConsent);
  if (window?.gtag) {
    window.gtag("consent", partialConsent);
  }
}

export function setFullConsent() {
  setConsent(fullConsent);
  if (window?.gtag) {
    window.gtag("consent", fullConsent);
  }
}

export function setAnalyticsConsent() {
  if (!areCookiesAccepted()) {
    setDefaultConsent();
  } else {
    setFullConsent();
  }
}
//#endregion

// FUNCTIONS
const functions = getFunctions(app, "europe-west1");
export const checkId = httpsCallable(functions, "checkId");

// STORAGE

const storage = getStorage(app);

export function saveByteToFile(reportName, byte) {
  var blob = new Blob([byte], { type: "application/pdf" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
}

export async function getMainFile(id) {
  return new Promise((resolve, reject) => {
    const fileRef = ref(storage, `${id}/main.pdf`);
    getBytes(fileRef)
      .then((bytes) => {
        return resolve(bytes);
      })
      .catch((error) => {
        if (!error || !error.code) {
          return reject("Unknown error");
        }
        switch (error.code) {
          case "storage/object-not-found":
            return reject("Not found");
          case "storage/unauthorized":
            return reject("Not authorized");
          case "storage/canceled":
            return reject("Action canceled");
          case "storage/unknown":
            return reject("Unknown storage error");
          default:
            return reject(`Unknown error: ${error}`);
        }
      });
  });
}

function getCleanName(name) {
  return name
    .replaceAll(" ", "")
    .replaceAll("\\", "")
    .replaceAll("/", "")
    .toUpperCase();
}

export async function getDetailFile(id, spzn) {
  return new Promise((resolve, reject) => {
    const fileRef = ref(storage, `${id}/${getCleanName(spzn)}.pdf`);
    getBytes(fileRef)
      .then((bytes) => {
        return resolve(bytes);
      })
      .catch((error) => {
        if (!error || !error.code) {
          return reject("Unknown error");
        }
        switch (error.code) {
          case "storage/object-not-found":
            return reject("Not found");
          case "storage/unauthorized":
            return reject("Not authorized");
          case "storage/canceled":
            return reject("Action canceled");
          case "storage/unknown":
            return reject("Unknown storage error");
          default:
            return reject(`Unknown error: ${error}`);
        }
      });
  });
}
